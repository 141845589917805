import React, { useCallback, useRef, useState } from 'react'
import { Box, Button, IconButton, Paper, Stack, Tabs, ToggleButton, useMediaQuery, useTheme } from '@mui/material'
import { callServerAPI, getNameFromTotalEnergiesEmail, formatShortDateString } from '../../helpers/Helpers'
import { AgendaItemType, preloader, AgendaItemStatus, GlobalRole, MeetingStatus, MoMStatus, globalStore, CpscMeetingHubMessageType } from '../../helpers/Constants'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Swal from 'sweetalert2'
import '../../css/presentation.css'
import { HowToVote, Approval, ArrowBackIos } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton } from '@mui/material';
import Tab from '@mui/material/Tab';
import MeetingVoting from './MeetingVoting';
import CustomTabPanel from '../../components/tabs/CustomTabPanel';
import MeetingApproval from './MeetingApproval';
import { Call, CallEnd, PictureAsPdf, PlayArrow, Stop } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import MinutesOfMeeting from './MinutesOfMeeting';
import FlexBetween from '../../components/FlexBetween';
import MeetingNav from '../../components/navbar/MeetingNav';
import CompletedMeetings from './CompletedMeetings';
import CustomDialog from '../../components/dialogs/CustomDialog';

const MySwal = withReactContent(Swal);
axios.defaults.withCredentials = true;

function MeetingPresentation() {
    const theme = useTheme();
    const isNonMobile = useMediaQuery('(min-width: 600px)');
    const { id } = useParams();

    /** @type import('react').MutableRefObject<WebSocket> */
    var socket = useRef(null);

    /** @type [TepngUser, any, any] */
    const [loggedOnTepngUser, ,] = globalStore.useState("loggedOnTepngUser");
    const { enqueueSnackbar } = useSnackbar();
    /** @type [AgendaItem, import('react').Dispatch<import('react').SetStateAction<AgendaItem>>] */
    const [currentAgendaItem, setCurrentAgendaItem] = useState(null);

    /** @type [CpscRequestsForMeetingDto, import('react').Dispatch<import('react').SetStateAction<CpscRequestsForMeetingDto>>] */
    const [meetingCpscRequestList, setMeetingCpscRequestList] = useState(null)

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [presentationPdfObjectUrl, setPresentationPdfObjectUrl] = useState(null);

    /** @type [Meeting, import('react').Dispatch<import('react').SetStateAction<Meeting>>] */
    const [meeting, setMeeting] = useState(null)
    /** @type { [Meeting[], Function] } */
    let [completedMeetings, setCompletedMeetings] = useState([]);
    /** @type [boolean, import('react').Dispatch<import('react').SetStateAction<boolean>>] */
    const [joinedMeeting, setJoinedMeeting] = useState(false)

    /** @type [boolean, import('react').Dispatch<import('react').SetStateAction<boolean>>] */
    const [showParticipants, setShowParticipants] = useState(window.innerWidth < 768 ? false : true)


    /** @type [boolean, import('react').Dispatch<import('react').SetStateAction<boolean>>] */
    const [showParticipantsMobile, setShowParticipantsMobile] = useState(false)

    /** @type [MeetingParticipant[], import('react').Dispatch<import('react').SetStateAction<MeetingParticipant[]>>] */
    const [meetingParticipants, setMeetingParticipants] = useState([])

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [currentAgendaItemStatus, setCurrentAgendaItemStatus] = useState("");

    /** @type [string, import('react').Dispatch<import('react').SetStateAction<string>>] */
    const [completedMeetingID, setCompletedMeetingID] = useState("None Selected");
    /** @type [CpscDecision[], import('react').Dispatch<import('react').SetStateAction<CpscDecision[]>>] */
    const [cpscDecisions, setCpscDecisions] = useState([]);

    /** @type [CpscVote[], import('react').Dispatch<import('react').SetStateAction<CpscVote[]>>] */
    const [cpscVotes, setCpscVotes] = useState([]);

    /** @type [number, import('react').Dispatch<import('react').SetStateAction<number>>] */
    const [selectedTabValue, setSelectedTabValue] = React.useState(1);

    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [cpscMembers, setCpscMembers] = useState([]);

    /** @type [string[], import('react').Dispatch<import('react').SetStateAction<string[]>>] */
    const [offlineCpscMembers, setOfflineCpscMembers] = useState([]);

    const recieveWebSocketEvents = useCallback(/** @param {MessageEvent<any>} event, @param {Meeting} meeting  */(event, meeting) => {
        /** @type CpscMeetingHubMessage */

        const msg = JSON.parse(event.data);
        switch (msg.messageType) {
            case CpscMeetingHubMessageType.INITIAL_DATA:
                /**@type  InitialCpscMeetingData*/
                const initialData = msg.data;
                setMeetingParticipants(initialData.participants)
                const local_currentAgendaItem = meeting.agenda.find(e => e.id === initialData.currentAgendaItemId)
                setCurrentAgendaItem(local_currentAgendaItem)
                setCurrentAgendaItemStatus(initialData.currentAgendaItemStatus);
                setCpscVotes(initialData.cpscVotes)
                setCpscDecisions(initialData.cpscDecisions)
                break;
            case CpscMeetingHubMessageType.PARTICIPANT_JOINED:
                /**@type  MeetingParticipant*/
                const newParticipant = msg.data;
                if (loggedOnTepngUser.email.toUpperCase() !== newParticipant.email.toUpperCase())
                    setMeetingParticipants(participants =>
                        participants.findIndex(e => e.email.toUpperCase() === newParticipant.email.toUpperCase()) < 0
                            ? [...participants, newParticipant]
                            : participants.map(inst => inst.email.toUpperCase() !== newParticipant.email.toUpperCase() ? inst : { ...inst, online: true, role: newParticipant.role })
                    )
                break;
            case CpscMeetingHubMessageType.PARTICIPANT_LEFT:
                /**@type  string */
                const email = msg.data;
                setMeetingParticipants(participants => participants.map(inst => inst.email.toUpperCase() !== email.toUpperCase() ? inst : { ...inst, online: false }))
                break;
            case CpscMeetingHubMessageType.CURRENT_AGENDA_ITEM_STATUS_UPDATED:
                /**@type  {AgendaItemStatusUpdateDto}*/
                const agendaItemStatusUpdate = msg.data;
                console.log(`CURRENT_AGENDA_ITEM_STATUS_UPDATED:${agendaItemStatusUpdate.agendaItemStatus} ${agendaItemStatusUpdate.agendaItemId}`)
                setCurrentAgendaItemStatus(agendaItemStatusUpdate.agendaItemStatus)
                switch (agendaItemStatusUpdate.agendaItemStatus) {
                    case AgendaItemStatus.PRESENTATION_ONGOING:
                        //setSelectedTabValue(2); //removed so as not to force CPSC memebrs to switch to presentation mode when we move to next agenda item
                        break;
                    case AgendaItemStatus.VOTING_ONGOING:
                        setCpscVotes(cpscVotes => cpscVotes.filter(e => e.agendaItemId !== agendaItemStatusUpdate.agendaItemId))//clear previous cpsc votes for agenda
                        setSelectedTabValue(3);
                        break;
                    case AgendaItemStatus.APPROVAL_ONGOING:
                        setSelectedTabValue(4);
                        break;
                    default:
                        break;
                }
                break;
            case CpscMeetingHubMessageType.CURRENT_AGENDA_ITEM_UPDATED:
                const updated_currentAgendaItem = meeting.agenda.find(e => e.id === msg.data);
                setCurrentAgendaItem(updated_currentAgendaItem)
                console.log("CURRENT_AGENDA_ITEM_UPDATED:" + updated_currentAgendaItem.title)
                break;
            case CpscMeetingHubMessageType.MEETING_ENDED:
                callServerAPI('GET', `/api/Meeting/GetAScheduledMeeting?meetingId=${id}`, null, true)
                    .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                        setMeeting(response.data.data);
                    })
                break;
            case CpscMeetingHubMessageType.APPROVALS_SUBMITTED:
                /**@type  CpscDecision[] */
                const cpscDecisions = msg.data; //all cpsc decisions for the meeting are sent to connected clients
                setCpscDecisions(cpscDecisions)
                break;
            case CpscMeetingHubMessageType.VOTES_SUBMITTED:
                /**@type  CpscVote[] */
                const votesSubmitted = msg.data;
                console.log("votesSubmitted.Length=" + votesSubmitted.length)
                setCpscVotes(cpscVotes => [...votesSubmitted, ...cpscVotes])
                break;
            default:
        }
    }, [loggedOnTepngUser, id])

    const handleClickOpen = () => {

        setShowParticipantsMobile(true);
    };

    /** @param {string} agendaItemId */
    function updateCurrentAgendaItemId(agendaItemId) {
        callServerAPI('PATCH', `/api/MeetingPresentation/UpdateCurrentAgendaItemId?agendaItemId=${agendaItemId}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<String>> } */ response) {
            })

    }

    /** @param {string} agendaItemStatus */
    const updateCurrentAgendaItemStatus = (agendaItemStatus) => {
        callServerAPI('PATCH', `/api/MeetingPresentation/UpdateCurrentAgendaItemStatus?status=${agendaItemStatus}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                //setCurrentAgendaItemStatus(agendaItemStatus);
            })

    }

    const connectToMeeting = useCallback(/** @param {string} joinURL, @param {Meeting} meeting  */(joinURL, meeting) => {
        // Create WebSocket connection.
        socket.current = new WebSocket(joinURL);
        socket.current.addEventListener("open", (event) => {
            callServerAPI('PATCH', `/api/MeetingPresentation/UpdateParticipantJoined`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                    setJoinedMeeting(true);
                })
        });
        socket.current.addEventListener("close", (event) => {
            callServerAPI('PATCH', `/api/MeetingPresentation/UpdateParticipantLeft`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                    setJoinedMeeting(false);
                })
        });
        socket.current.addEventListener("message", (event) => recieveWebSocketEvents(event, meeting));
    }, [recieveWebSocketEvents])

    function leaveMeeting() {
        socket.current.close()
    }

    const joinMeeting = useCallback(/** @param {Meeting} meeting */(meeting) => {
        callServerAPI('GET', `/api/MeetingPresentation/GetCPSCMeetingJoinUrl?meetingId=${meeting.id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<String>> } */ response) {
                connectToMeeting(response.data.data, meeting);
            })

    }, [connectToMeeting])

    function startMeetingPresentation() {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/MeetingPresentation/StartMeeting?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setMeeting(oldMeeting => ({ ...oldMeeting, status: MeetingStatus.Ongoing }))
                joinMeeting(meeting)
                MySwal.close();
                enqueueSnackbar('Meeting Started', { variant: 'success' });
            })
    }
    function endMeetingPresentation() {
        MySwal.fire(preloader);
        callServerAPI('PATCH', `/api/MeetingPresentation/EndCurrentMeeting?meetingId=${id}`, null, true)
            .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<any>> } */ response) {
                setMeeting(oldMeeting => ({ ...oldMeeting, status: MeetingStatus.Completed, momStatus: MoMStatus.Draft }))
                MySwal.close();
                enqueueSnackbar('Meeting Ended', { variant: 'success' });
            })
    }

    useEffect(() => {
        const getAScheduledMeeting = async () => {
            callServerAPI('GET', `/api/Meeting/GetAScheduledMeeting?meetingId=${id}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting>> } */ response) {
                    setMeeting(response.data.data);
                    if (response.data.data.status === MeetingStatus.Ongoing) {
                        joinMeeting(response.data.data);
                    }
                })

        }
        const getRequests = async () => {
            callServerAPI('GET', `/api/Meeting/GetCpscRequestsForMeeting?meetingId=${id}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<CpscRequestsForMeetingDto>> } */ response) {
                    setMeetingCpscRequestList(response.data.data);
                })

        }
        const getCpscMembers = async () => {
            callServerAPI('GET', `/api/Roles/GetAllUserEmailsForARole?roleName=${GlobalRole.CPSCMember}`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<string[]>> } */ response) {
                    setCpscMembers(response.data.data);
                })

        }

        const getAllCompletedMeetings = async () => {
            callServerAPI('GET', `/api/Meeting/GetAllCompletedMeetings`, null, true)
                .then(function (/** @type { import("axios").AxiosResponse<ServerAPIResponse<Meeting[]>> } */ response) {
                    setCompletedMeetings(response.data.data);


                })

        }
        getAllCompletedMeetings();
        getAScheduledMeeting();
        getRequests();
        getCpscMembers();
    }, [id, joinMeeting])


    useEffect(() => {
        const loadPresentationPdf = async () => {
            setPresentationPdfObjectUrl(null)
            if (currentAgendaItem == null) return
            if (currentAgendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT && currentAgendaItem.safetyOrSustainabilityMomentSlideUploaded === false) {
                return
            }
            if (currentAgendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT && currentAgendaItem.safetyOrSustainabilityMomentSlideUploaded === true) {
                viewSafetyMomentPdf(currentAgendaItem.id, id);
                return
            }
            if (currentAgendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST) {
                viewAggregatedPdf(currentAgendaItem.aggregatedCpscRequestId);
                return
            }
            if (currentAgendaItem.agendaItemType !== AgendaItemType.AGGREGATED_CPSC_REQUEST && currentAgendaItem.agendaItemType !== AgendaItemType.SAFETY_MOMENT) {
                viewCPSCPdf(currentAgendaItem.cpscRequestId);
                return
            }
        }
        loadPresentationPdf();
    }, [currentAgendaItem, id])

    const handleStep = (agendaItem, index) => () => {
        //if user is not GCC return
        //setCurrentAgendaItem(agendaItem)
        if (loggedOnTepngUser.roles.includes(GlobalRole.GCC)) {
            updateCurrentAgendaItemId(agendaItem.id)
        }

    };

    const handleNext = () => {
        if (currentAgendaItem.serialNumber === meeting.agenda.length) return
        const agendaItem = meeting.agenda.find(e => e.serialNumber === currentAgendaItem.serialNumber + 1)
        //setCurrentAgendaItem(agendaItem)
        updateCurrentAgendaItemId(agendaItem.id)
    }

    const handlePrevious = () => {
        if (currentAgendaItem.serialNumber === 1) return
        const agendaItem = meeting.agenda.find(e => e.serialNumber === currentAgendaItem.serialNumber - 1)
        //setCurrentAgendaItem(agendaItem)
        updateCurrentAgendaItemId(agendaItem.id)
    }

    function viewCPSCPdf(cpscRequestId) {
        callServerAPI('GET', `/api/CpscRequest/GetDossierPdf?requestId=${cpscRequestId}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                setPresentationPdfObjectUrl((URL.createObjectURL(response.data)))
                MySwal.close();
            })

    }

    function viewAggregatedPdf(aggregatedCpscRequestId) {
        callServerAPI('GET', `/api/CpscRequest/GetAggregatedDossierPdf?aggregateRequestId=${aggregatedCpscRequestId}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                setPresentationPdfObjectUrl((URL.createObjectURL(response.data)))
                MySwal.close();
            })

    }

    function viewSafetyMomentPdf(agendaItemId, meetingId) {
        callServerAPI('GET', `/api/Meeting/DownloadSafetyOrSustainabilityMomentPdf?meetingId=${meetingId}&agendaItemId=${agendaItemId}`, null, true, 'blob')
            .then(function (/** @type { import("axios").AxiosResponse<any> } */ response) {
                setPresentationPdfObjectUrl((URL.createObjectURL(response.data)))
                MySwal.close();
            })

    }

    useEffect(() => {
        const getOfflineCpscMembers = async () => {
            setOfflineCpscMembers(cpscMembers.filter(email => meetingParticipants.findIndex(p => p.email.toUpperCase() === email.toUpperCase()) < 0))
        }
        getOfflineCpscMembers();
    }, [cpscMembers, meetingParticipants])

    const handleEndMeeting = (id) => {
        MySwal.fire({
            title: 'Alert',
            html: `<small> Are you sure you want to end this Meeting ?</small>`,
            icon: 'info',
            cancelButtonColor: '#DC143C',
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: '#02b702',
            confirmButtonText: 'Yes'

        })
            .then((result) => {
                if (result.isConfirmed) {
                    endMeetingPresentation();

                }
            })
    };
    const navigate = useNavigate();
    const handleHistory = () => {
        navigate('/meeting-list')
    }
    return (
        completedMeetings && meeting && meetingCpscRequestList &&
        <>
            <MeetingNav />
            <Box m="1.0rem 1.0rem">
                <Box sx={{ width: '100%' }}>
                    <Stack >
                        <Paper>
                            <FlexBetween>
                                <FlexBetween>
                                    {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && (
                                        <IconButton onClick={handleHistory} sx={{ fontSize: '13px' }}>
                                            <ArrowBackIos />
                                        </IconButton>
                                    )}
                                    <Typography variant="h6" color={theme.palette.text.secondary} fontWeight="bold" sx={{ margin: '1.0rem', }}>
                                        Digital CPSC Meeting - {formatShortDateString(meeting.meetingDateTime)}
                                    </Typography>
                                </FlexBetween>
                                {isNonMobile &&
                                    <div className=' d-flex  justify-content-between' style={{ padding: '5px' }} >
                                        {meeting.status === MeetingStatus.Ongoing && joinedMeeting === true &&
                                            <ToggleButton size='small' sx={{ fontSize: '12px', fontWeight: '600' }} value={showParticipants} onClick={() => { setShowParticipants(!showParticipants) }} > <Diversity3Icon />&nbsp; Participants</ToggleButton>
                                        }
                                        &nbsp;
                                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && meeting.status === MeetingStatus.Scheduled &&
                                            <Button color='success' size='small' sx={{ fontSize: '12px', fontWeight: '600' }} onClick={startMeetingPresentation} disabled={meeting.agenda.length === 0} variant="contained" ><PlayArrow fontSize='medium' />&nbsp;Start Meeting</Button>
                                        }
                                        &nbsp;
                                        {meeting.status === MeetingStatus.Ongoing && joinedMeeting === true && (loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CpscSecretary)) &&

                                            <Button style={{ borderRadius: '5px', borderColor: 'red', fontSize: '12px', fontWeight: '600' }} size='small' color='secondary' onClick={leaveMeeting} variant="outlined"   ><CallEnd fontSize='medium' />&nbsp;&nbsp;Leave Meeting</Button>
                                        }
                                        &nbsp;
                                        {joinedMeeting === false && meeting.status === MeetingStatus.Ongoing && (loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser.roles.includes(GlobalRole.GCC)) &&
                                            <Button color='success' sx={{ fontSize: '12px', fontWeight: '600' }} onClick={() => joinMeeting(meeting)} variant="outlined"   ><Call fontSize='large' />&nbsp;Join Meeting</Button>
                                        }
                                        &nbsp;
                                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && meeting.status === MeetingStatus.Ongoing &&
                                            <Button style={{ borderRadius: '5px', fontSize: '12px', fontWeight: '600' }} size='small' color='error' onClick={handleEndMeeting} variant="contained" ><Stop fontSize='medium' />&nbsp;End Meeting</Button>
                                        }
                                    </div>
                                }
                            </FlexBetween>
                        </Paper>
                    </Stack>
                    <br />
                    {!isNonMobile && (
                        <>
                            <Stack >
                                <Paper>

                                    <div className=' d-flex  justify-content-center' style={{ padding: '5px', width: '100%', height: '50px' }} >
                                        {meeting.status === MeetingStatus.Ongoing && joinedMeeting === true &&
                                            <ToggleButton value={showParticipants} onClick={() => { handleClickOpen() }} > <Diversity3Icon fontSize='large' /> &nbsp; Participants</ToggleButton>
                                        }
                                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && meeting.status === MeetingStatus.Scheduled &&
                                            <Button color='success' size='small' onClick={startMeetingPresentation} variant="contained" disabled={meeting.agenda.length === 0}><PlayArrow fontSize='large' />&nbsp;Start </Button>
                                        }
                                        &nbsp;
                                        {meeting.status === MeetingStatus.Ongoing && joinedMeeting === true && (loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser.roles.includes(GlobalRole.GCC) || loggedOnTepngUser.roles.includes(GlobalRole.CpscSecretary)) &&

                                            <Button style={{ borderRadius: '5px', borderColor: 'red', fontWeight: 'bold' }} size='small' color='secondary' onClick={leaveMeeting} variant="outlined"   ><CallEnd fontSize='large' />&nbsp;&nbsp;Leave </Button>
                                        }
                                        {joinedMeeting === false && meeting.status === MeetingStatus.Ongoing && (loggedOnTepngUser.roles.includes(GlobalRole.CPSCMember) || loggedOnTepngUser.roles.includes(GlobalRole.GCC)) &&
                                            <Button color='success' onClick={() => joinMeeting(meeting)} variant="outlined"   ><Call fontSize='large' />&nbsp;Join </Button>
                                        }
                                        &nbsp;
                                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) && meeting.status === MeetingStatus.Ongoing &&
                                            <Button style={{ borderRadius: '5px', fontWeight: 'bold' }} size='small' color='error' onClick={handleEndMeeting} variant="contained" ><Stop fontSize='large' />&nbsp;End </Button>
                                        }
                                    </div>

                                </Paper>
                            </Stack>
                        </>

                    )}
                </Box>
                <Box  >

                    <Box >
                        <div className='row '>

                            {((meeting.status === MeetingStatus.Scheduled) || (meeting.status === MeetingStatus.Ongoing && joinedMeeting === false)) &&
                                <>
                                    <div style={{ margin: '0px !important' }}>
                                        {
                                            <>
                                                <div style={{ marginBottom: "10px" }}>
                                                    <span style={{ fontWeight: "bold", color: "#f54a5a" }}>
                                                        <span style={{ color: '#f54a5a', fontSize: 12, fontWeight: 'bold', marginTop: '5px', marginBottom: '6px' }} ></span>
                                                    </span>
                                                </div>

                                                <div className=' shadow-sm bg-white  px-4 py-4' style={{ paddingTop: '0px !important' }} >

                                                    <div className='content-desktop'>

                                                        <Tabs variant='fullWidth' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example">
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Agenda" value={1} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Presentation" value={2} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Voting" value={3} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Approval" value={4} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Previous Minutes of Meetings" value={5} />
                                                        </Tabs>
                                                    </div>
                                                    <div className='content-mobile'>
                                                        <Tabs variant='scrollable' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                                            allowScrollButtonsMobile>

                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Agenda" value={1} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Presentation" value={2} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Voting" value={3} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Approval" value={4} />
                                                            <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Previous Minutes of Meetings" value={5} />
                                                        </Tabs>
                                                    </div>

                                                    <CustomTabPanel value={selectedTabValue} index={1}>
                                                        <div>

                                                            <br />
                                                            <Stepper orientation="vertical" nonLinear >
                                                                {meeting.agenda.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1).map((agendaItem, index) => (
                                                                    <Step key={index}  >
                                                                        <StepButton color="inherit" disabled>
                                                                            <StepLabel StepIconProps={{ classes: { completed: 'stepperCompletedStepIcon' } }}
                                                                                classes={{ active: 'stepperActiveStepLabel', label: 'stepperStepLabel', labelContainer: 'stepperStepLabelContainer', completed: 'stepperCompletedStepLabel' }}>
                                                                                <div className='d-flex  justify-content-between'>
                                                                                    <div style={{ fontSize: '13px' }}>{agendaItem.title} </div>
                                                                                </div>
                                                                            </StepLabel>
                                                                        </StepButton>
                                                                    </Step>
                                                                ))}
                                                                {meeting.agenda.length === 0 &&
                                                                    <div>
                                                                        <p>There is no Agenda for this Meeting</p>
                                                                        {loggedOnTepngUser.roles.includes(GlobalRole.GCC) &&
                                                                            <Button variant='contained' onClick={(e) => { navigate(`/edit-meeting/${meeting.id}`) }} style={{ fontWeight: 'bold' }}> Add Agenda</Button>
                                                                        }
                                                                    </div>
                                                                }
                                                            </Stepper>
                                                        </div>
                                                    </CustomTabPanel>


                                                    <CustomTabPanel value={selectedTabValue} index={2}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Diversity3Icon style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                                                            <p style={{ fontSize: '12px', fontStyle: 'italic' }}><b>{meeting.status === MeetingStatus.Scheduled ? 'This Meeting has not Started' : 'Please click the "Join Meeting" button to join the meeting'} </b> </p>

                                                        </div>
                                                    </CustomTabPanel>



                                                    <CustomTabPanel value={selectedTabValue} index={3}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Diversity3Icon style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                                                            <p style={{ fontSize: '12px', fontStyle: 'italic' }}><b>{meeting.status === MeetingStatus.Scheduled ? 'This Meeting has not Started' : 'Please click the "Join Meeting" button to join the meeting'} </b>  </p>

                                                        </div>
                                                    </CustomTabPanel>



                                                    <CustomTabPanel value={selectedTabValue} index={4}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Diversity3Icon style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                                                            <p style={{ fontSize: '12px', fontStyle: 'italic' }}><b>{meeting.status === MeetingStatus.Scheduled ? 'This Meeting has not Started' : 'Please click the "Join Meeting" button to join the meeting'} </b>  </p>

                                                        </div>
                                                    </CustomTabPanel>

                                                    <CustomTabPanel value={selectedTabValue} index={5}>
                                                        <div>
                                                            {completedMeetings.length === 0 &&
                                                                <div> There are no Previous Minutes of Meetings</div>
                                                            }

                                                            {completedMeetings.length !== 0 &&
                                                                <>
                                                                    <CompletedMeetings completedMeetingID={completedMeetingID} setCompletedMeetingID={setCompletedMeetingID} completedMeetings={completedMeetings} />

                                                                </>
                                                            }
                                                        </div>
                                                    </CustomTabPanel>

                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className='meeting_stats col-12 py-2' style={{ width: '100%', bottom: '0px', position: 'fixed' }}>
                                        <div style={{ marginLeft: '10px' }}>{meeting.status === MeetingStatus.Scheduled ? 'This Meeting has not Started' : (loggedOnTepngUser.roles.includes(GlobalRole.GCC) ? 'Please click the "Join Meeting" button to join the meeting' : 'Please click the "Join Meeting" button to join the meeting')}</div>
                                    </div>
                                </>
                            }

                            {meeting.status === MeetingStatus.Ongoing && joinedMeeting === true &&
                                <>
                                    <div className={showParticipants === true ? 'presentation_info col-12 ' : 'col-12'} style={{ margin: '0px !important' }}>
                                        {joinedMeeting === true && currentAgendaItem && currentAgendaItemStatus &&
                                            <>
                                                <div style={{ marginBottom: "10px" }}>
                                                    <div >
                                                        <span style={{ fontWeight: "bold", color: "#f54a5a" }}>
                                                            <span style={{ color: '#f54a5a', fontSize: 14, fontWeight: 'bold', marginTop: '5px', marginBottom: '6px' }} >  {currentAgendaItem.title}</span>
                                                        </span>
                                                    </div>

                                                </div>

                                                <Stack style={{ paddingTop: '0px !important' }} >
                                                    <Paper className='  px-2 py-4'>
                                                        <div className='content-desktop'>
                                                            <Tabs value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example">

                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Agenda" value={1} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Presentation" value={2} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Voting" value={3} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Approval" value={4} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Previous Minutes of Meetings" value={5} />
                                                            </Tabs>
                                                        </div>
                                                        <div className='content-mobile'>
                                                            <Tabs variant='scrollable' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                                                allowScrollButtonsMobile>

                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Agenda" value={1} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Presentation" value={2} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Voting" value={3} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Approval" value={4} />
                                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Previous Minutes of Meetings" value={5} />
                                                            </Tabs>
                                                        </div>

                                                        <CustomTabPanel value={selectedTabValue} index={1}>
                                                            <div>
                                                                {loggedOnTepngUser.roles.includes(GlobalRole.GCC) &&
                                                                    <>
                                                                        <div className='content-desktop'>
                                                                            <div style={{ float: 'right' }}>
                                                                                <Button className='bck' color='secondary' variant="contained" disabled={currentAgendaItem.serialNumber === 1} onClick={handlePrevious} ><ArrowBackIosIcon />&nbsp;<b>Back</b></Button>
                                                                                <Button className='nxt' color='primary' variant="contained" disabled={currentAgendaItem.serialNumber === meeting.agenda.length} onClick={handleNext}  ><b>Next</b>&nbsp;<ArrowForwardIosIcon /></Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='content-mobile'>
                                                                            <div style={{ float: 'right' }}>
                                                                                <Button style={{ fontSize: '9px' }} className='bck' color='secondary' variant="contained" disabled={currentAgendaItem.serialNumber === 1} onClick={handlePrevious} ><ArrowBackIosIcon />&nbsp;Back</Button>
                                                                                <Button style={{ fontSize: '9px' }} className='nxt' color='primary' variant="contained" disabled={currentAgendaItem.serialNumber === meeting.agenda.length} onClick={handleNext}  >Next&nbsp;<ArrowForwardIosIcon /></Button>
                                                                            </div>
                                                                        </div>
                                                                    </>


                                                                }
                                                                <br />
                                                                <Stepper style={{ width: '100%' }} orientation="vertical" activeStep={currentAgendaItem.serialNumber - 1} nonLinear >
                                                                    {meeting.agenda.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1).map((agendaItem, index) => (
                                                                        <Step key={index}  >
                                                                            <StepButton color="inherit" disabled={!loggedOnTepngUser.roles.includes(GlobalRole.GCC)} onClick={handleStep(agendaItem, index)}>
                                                                                <StepLabel style={{ width: '100%' }} StepIconProps={{ classes: { completed: 'stepperCompletedStepIcon' } }}
                                                                                    classes={{ active: 'stepperActiveStepLabel', label: 'stepperStepLabel', labelContainer: 'stepperStepLabelContainer', completed: 'stepperCompletedStepLabel' }}>
                                                                                    <div className='d-flex  justify-content-between'>
                                                                                        <div style={{ fontSize: '13px' }} > {agendaItem.title} </div>
                                                                                        <div className='d-flex flex-row-reverse'>
                                                                                            {agendaItem.id === currentAgendaItem.id ?

                                                                                                <div
                                                                                                    style={{ float: 'right', marginLeft: "8px" }}
                                                                                                    className={agendaItem.id === currentAgendaItem.id && currentAgendaItemStatus === AgendaItemStatus.PRESENTATION_ONGOING ? 'presenting' : (currentAgendaItemStatus === AgendaItemStatus.VOTING_ONGOING ? 'voting' : (currentAgendaItemStatus === AgendaItemStatus.APPROVAL_ONGOING ? 'approval' : (currentAgendaItemStatus === AgendaItemStatus.COMPLETED ? 'completed' : 'null')))} >  &nbsp;{agendaItem.id === currentAgendaItem.id ? `${currentAgendaItemStatus}` : ""} &nbsp;</div>
                                                                                                : <div></div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </StepLabel>
                                                                            </StepButton>
                                                                        </Step>
                                                                    ))}

                                                                </Stepper>
                                                            </div>
                                                        </CustomTabPanel>



                                                        {presentationPdfObjectUrl &&
                                                            <CustomTabPanel value={selectedTabValue} index={2}>
                                                                <div className='content-desktop'>
                                                                    <iframe src={presentationPdfObjectUrl + '#view=FitH'} height="700" width="100%" title="Presentation" style={{ paddingBottom: '15px' }}></iframe>
                                                                </div>
                                                                <div className='content-mobile'>
                                                                    <br />
                                                                    <Button color='success' onClick={() => window.open(presentationPdfObjectUrl)} variant="contained" ><PictureAsPdf fontSize='medium' />&nbsp;View {currentAgendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT ? 'Safety/Sustainability Moment' : 'Dossier'} Pdf</Button>
                                                                </div>
                                                            </CustomTabPanel>
                                                        }
                                                        {currentAgendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT &&
                                                            <CustomTabPanel value={selectedTabValue} index={3}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <HowToVote style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                                                                    <p style={{ fontSize: '14px', fontStyle: 'italic' }}><b>No Voting for Sustainability Moment  </b>  </p>

                                                                </div>
                                                            </CustomTabPanel>
                                                        }
                                                        {currentAgendaItem.agendaItemType !== AgendaItemType.SAFETY_MOMENT && cpscVotes &&
                                                            <CustomTabPanel value={selectedTabValue} index={3}>
                                                                <div style={{ padding: '14px' }}>
                                                                    <MeetingVoting updateCurrentAgendaItemStatus={updateCurrentAgendaItemStatus} currentAgendaItemStatus={currentAgendaItemStatus} cpscVotes={cpscVotes} meetingCpscRequestList={meetingCpscRequestList} currentAgendaItem={currentAgendaItem} />
                                                                </div>
                                                            </CustomTabPanel>
                                                        }
                                                        {currentAgendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT &&
                                                            <CustomTabPanel value={selectedTabValue} index={4}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <Approval style={{ height: '100px', width: '100px', color: '#2054a1' }} />
                                                                    <p style={{ fontSize: '14px', fontStyle: 'italic' }}><b>No Approval for Sustainability Moment  </b>  </p>

                                                                </div>
                                                            </CustomTabPanel>
                                                        }
                                                        {currentAgendaItem.agendaItemType !== AgendaItemType.SAFETY_MOMENT && cpscDecisions &&
                                                            <CustomTabPanel value={selectedTabValue} index={4}>
                                                                <div style={{ padding: '14px' }}>
                                                                    <MeetingApproval updateCurrentAgendaItemStatus={updateCurrentAgendaItemStatus} currentAgendaItemStatus={currentAgendaItemStatus} cpscDecisions={cpscDecisions} meetingCpscRequestList={meetingCpscRequestList} currentAgendaItem={currentAgendaItem} />
                                                                </div>
                                                            </CustomTabPanel>
                                                        }


                                                        <CustomTabPanel value={selectedTabValue} index={5}>
                                                            <div>
                                                                {completedMeetings.length === 0 &&
                                                                    <div> There are no Previous Minutes of Meetings</div>
                                                                }

                                                                {completedMeetings.length !== 0 &&
                                                                    <>
                                                                        <CompletedMeetings completedMeetingID={completedMeetingID} setCompletedMeetingID={setCompletedMeetingID} completedMeetings={completedMeetings} />

                                                                    </>
                                                                }
                                                            </div>
                                                        </CustomTabPanel>
                                                    </Paper>
                                                </Stack>
                                            </>
                                        }
                                    </div>

                                    {
                                        showParticipants &&

                                        <div className='attendance_info col-12 s ' style={{ margin: '0px !important' }}>
                                            <button className='attendance'>PARTICIPANTS < CloseIcon onClick={() => { setShowParticipants(false) }} style={{ float: 'right' }} /></button>



                                            <div className=' participants shadow-sm bg-white px-1 py-2 ' style={{ paddingTop: '0px !important', }} >
                                                {joinedMeeting === true &&

                                                    <>
                                                        <div>
                                                            <div className='role_header'>
                                                                <div>GCC</div>
                                                            </div>
                                                            <div style={{ padding: '5px' }}>
                                                                {meetingParticipants.filter(e => e.role === GlobalRole.GCC).map((item, index) => {
                                                                    return <div key={index} className='participant'>
                                                                        <div>
                                                                            <svg className='online' height="50" width="50">
                                                                                <circle cx="30" cy="30" r="5" fill={item.online ? "green" : "orange"} />
                                                                            </svg>
                                                                            {getNameFromTotalEnergiesEmail(item.email)}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='role_header'>
                                                                <div>CPSC Members</div>
                                                            </div>
                                                            <div style={{ padding: '5px' }}>
                                                                {meetingParticipants.filter(e => e.role === GlobalRole.CPSCMember || e.role === GlobalRole.CpscChairman).map((item, index) => {
                                                                    return <div key={index} className='participant'>
                                                                        <div>
                                                                            <svg className='online' height="50" width="50">
                                                                                <circle cx="30" cy="30" r="5" fill={item.online ? "green" : "orange"} />
                                                                            </svg>
                                                                            {getNameFromTotalEnergiesEmail(item.email)} {item.role === GlobalRole.CpscChairman ? " (Chairman)" : ""}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                                {offlineCpscMembers.map((email, index) => {
                                                                    return <div key={index} className='participant'>
                                                                        <div>
                                                                            <svg className='online' height="50" width="50">
                                                                                <circle cx="30" cy="30" r="5" fill="grey" />
                                                                            </svg>
                                                                            {getNameFromTotalEnergiesEmail(email)}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>

                                                    </>

                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className='content-mobile'>
                                        {showParticipantsMobile &&
                                            <>
                                                <CustomDialog dialogTitle='Participants' openDialog={showParticipants} setOpenDialog={setShowParticipants}>

                                                    <div className='  bg-white px-2 py-2 ' style={{ paddingTop: '0px !important', }} >
                                                        {joinedMeeting === true &&

                                                            <>
                                                                <div>
                                                                    <div className='role_header'>
                                                                        <div>GCC</div>
                                                                    </div>
                                                                    <div style={{ padding: '5px' }}>
                                                                        {meetingParticipants.filter(e => e.role === GlobalRole.GCC).map((item, index) => {
                                                                            return <div key={index} className='participant'>
                                                                                <div>
                                                                                    <svg className='online' height="50" width="50">
                                                                                        <circle cx="30" cy="30" r="5" fill={item.online ? "green" : "orange"} />
                                                                                    </svg>
                                                                                    {getNameFromTotalEnergiesEmail(item.email)}
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='role_header'>
                                                                        <div>CPSC Members</div>
                                                                    </div>
                                                                    <div style={{ padding: '5px' }}>
                                                                        {meetingParticipants.filter(e => e.role === GlobalRole.CPSCMember || e.role === GlobalRole.CpscChairman).map((item, index) => {
                                                                            return <div key={index} className='participant'>
                                                                                <div>
                                                                                    <svg className='online' height="50" width="50">
                                                                                        <circle cx="30" cy="30" r="5" fill={item.online ? "green" : "orange"} />
                                                                                    </svg>
                                                                                    {getNameFromTotalEnergiesEmail(item.email)} {item.role === GlobalRole.CpscChairman ? " (Chairman)" : ""}
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                        {offlineCpscMembers.map((email, index) => {
                                                                            return <div key={index} className='participant'>
                                                                                <div>
                                                                                    <svg className='online' height="50" width="50">
                                                                                        <circle cx="30" cy="30" r="5" fill="grey" />
                                                                                    </svg>
                                                                                    {getNameFromTotalEnergiesEmail(email)}
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>

                                                            </>

                                                        }
                                                    </div>

                                                </CustomDialog>
                                            </>
                                        }
                                    </div>
                                </>
                            }

                            {meeting.status === MeetingStatus.Completed &&
                                <>
                                    <div className=' shadow-sm bg-white  px-4 py-4' style={{ paddingTop: '0px !important' }} >
                                        <div className='content-desktop'>


                                            <Tabs variant='fullWidth' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                                allowScrollButtonsMobile>
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Summary" value={1} />
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Attendance" value={2} />
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Minutes of Meeting" value={3} />

                                            </Tabs>
                                        </div>
                                        <div className='content-mobile'>
                                            <Tabs variant='scrollable' value={selectedTabValue} onChange={(event, newValue) => setSelectedTabValue(Number(newValue))} aria-label="lab API tabs example" scrollButtons
                                                allowScrollButtonsMobile>
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Summary" value={1} />
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Attendance" value={2} />
                                                <Tab sx={{ fontSize: '12px', fontWeight: '600' }} label="Minutes of Meeting" value={3} />
                                            </Tabs>
                                        </div>
                                        <CustomTabPanel value={selectedTabValue} index={1}>
                                            <div>

                                                <br />
                                                <div className='  bg-white px-2 py-2' style={{ paddingTop: '0px !important' }} >
                                                    <div>

                                                        {meeting.agenda.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1).map((agendaItem, index) => (
                                                            <Accordion key={index} >
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel2a-content"
                                                                    id="panel2a-header" >
                                                                    <Typography >
                                                                        <div className='dossier-title' >{agendaItem.title}</div>
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        <div >
                                                                            {agendaItem.agendaItemType === AgendaItemType.SAFETY_MOMENT ? 'Safety Or Sustainability Moment'
                                                                                :
                                                                                (agendaItem.agendaItemType === AgendaItemType.AGGREGATED_CPSC_REQUEST ?
                                                                                    <div >

                                                                                        {meetingCpscRequestList.aggregatedCpscRequestList.find(e => e.id === agendaItem.aggregatedCpscRequestId).cpscRequests.map((cpscRequest, index) => {
                                                                                            return <div key={index} className='d-flex  justify-content-between requests'>
                                                                                                <li>{cpscRequest.contractTitle}</li>
                                                                                                <div>{meeting.cpscDecisions.find(e => e.cpscRequestId === cpscRequest.id)?.decision ?? "Not Presented"}</div>
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                    :
                                                                                    <div key={index} className='d-flex  justify-content-between requests'>
                                                                                        <li >{meetingCpscRequestList.cpscRequestList.find(e => e.id === agendaItem.cpscRequestId).contractTitle}</li>
                                                                                        <div >{meeting.cpscDecisions.find(e => e.cpscRequestId === agendaItem.cpscRequestId)?.decision ?? "Not Presented"}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))}





                                                    </div>
                                                </div>
                                            </div>
                                        </CustomTabPanel>


                                        <CustomTabPanel value={selectedTabValue} index={2}>
                                            <>
                                                <div>
                                                    <div className='role_header'>
                                                        <div>GCC</div>
                                                    </div>
                                                    <div style={{ padding: '5px' }}>
                                                        {meeting.participants.filter(e => e.role === GlobalRole.GCC).map((item, index) => {
                                                            return <div key={index} className='participant'>
                                                                <div>

                                                                    {getNameFromTotalEnergiesEmail(item.email)}
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='role_header'>
                                                        <div>CPSC Members</div>
                                                    </div>
                                                    <div style={{ padding: '5px' }}>

                                                        {meeting.participants.filter(e => e.role === GlobalRole.CPSCMember || e.role === GlobalRole.CpscChairman).map((item, index) => {
                                                            return <div key={index} className='participant'>
                                                                <div>

                                                                    {getNameFromTotalEnergiesEmail(item.email)} {item.role === GlobalRole.CpscChairman ? " (Chairman)" : ""}
                                                                </div>
                                                            </div>
                                                        })}

                                                    </div>
                                                </div>

                                            </>
                                        </CustomTabPanel>



                                        <CustomTabPanel value={selectedTabValue} index={3}>

                                            <MinutesOfMeeting enqueueSnackbar={enqueueSnackbar} meeting={meeting} setMeeting={setMeeting} meetingCpscRequestList={meetingCpscRequestList} />


                                        </CustomTabPanel>





                                    </div>

                                    {/*  <div className='meeting_stats col-12 py-2' style={{ width: '100%', bottom: '0px', position: 'absolute' }}>
          <div style={{ marginLeft: '10px' }}>This Meeting has ended. Please click <a href="google.com">here</a> to view minutes of meeting</div>
        </div> */}
                                </>
                            }

                        </div>
                    </Box >

                </Box >
            </Box >
        </>

    )
}

export default MeetingPresentation